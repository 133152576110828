import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { isProduction } from "@shared/utils/fullscriptEnv/fullscriptEnv";

import { ExperimentTester } from "../ExperimentTester";

const NonLazyExperimentTester = () => {
  const { isAdminImpersonating } = useSharedGlobalConfig();

  if (isProduction() && !isAdminImpersonating) return null;

  return <ExperimentTester />;
};

export { NonLazyExperimentTester };
