import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { CarouselItem, Spacer, Typography } from "@aviary";
import { AuthFooter } from "@shared/components/AuthFooter/AuthFooter";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import {
  FormWrapper,
  FooterSignIn,
  SignUpTitle,
  SignUpCarousel,
} from "@unauthenticated/shared/components";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { useLogPractitionerSignUp } from "@unauthenticated/shared/hooks";
import { PageContent, PatientCheckEmail, Page } from "@unauthenticated/signupSignin/components";
import { l } from "@unauthenticated/signupSignin/locales/i18n";
import { EmailSentPage } from "@unauthenticated/signupSignin/scenes/EmailSentPage";

import { ImAPractitionerButton } from "./ImAPractitionerButton";

import * as styles from "./PatientSignUpPage.styles";

interface Props {
  csuid: string;
  pageVersion: "prac_by_default_with_sso" | "prac_by_default";
}

const PatientSignUpPage = ({ csuid, pageVersion }: Props) => {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();

  useLogPractitionerSignUp({
    eventAggregateId: csuid,
    pageVersion,
    signUpPage: "patient_check_invitation_page",
    fields: [],
  });

  if (email) {
    return <EmailSentPage email={email} />;
  }

  const renderCarouselOrFooter = (isWithinViewport: boolean) => {
    if (isWithinViewport) {
      return (
        <SignUpCarousel>
          <CarouselItem>
            <Typography type="footnote">{t(l.practitionerSignUp.PatientUSPOne)}</Typography>
          </CarouselItem>
          <CarouselItem>
            <Typography type="footnote">{t(l.practitionerSignUp.PatientUSPTwo)}</Typography>
          </CarouselItem>
          <CarouselItem>
            <Typography type="footnote">{t(l.practitionerSignUp.PatientUSPThree)}</Typography>
          </CarouselItem>
          <CarouselItem>
            <Typography type="footnote">{t(l.practitionerSignUp.PatientUSPFour)}</Typography>
          </CarouselItem>
          <CarouselItem>
            <Typography type="footnote">{t(l.practitionerSignUp.PatientUSPFive)}</Typography>
          </CarouselItem>
        </SignUpCarousel>
      );
    }

    return (
      <AuthFooter>
        <FooterSignIn />
      </AuthFooter>
    );
  };

  return (
    <Page>
      <PageContent>
        <AuthHeaderWrapper>
          <AuthHeader route={authRoutes.login} />
        </AuthHeaderWrapper>
        <FormWrapper>
          <div css={styles.textAlign}>
            <ImAPractitionerButton />
            <SignUpTitle user={t(l.practitionerSignUp.Patient)} />
            <Typography css={styles.subTitle}>
              <Trans i18nKey={l.practitionerSignUp.LetsGetStarted}>
                Let’s get you started! All accounts are 100%{" "}
                <strong css={styles.freeText}>free</strong>
              </Trans>
            </Typography>
            <Spacer height="doubleHalf" />
            <PatientCheckEmail
              onEmailSent={setEmail}
              pageVersion={pageVersion}
              signUpPage="patient_check_invitation_page"
            />
          </div>
          {renderCarouselOrFooter(tablet.greaterThan)}
        </FormWrapper>
      </PageContent>
      {renderCarouselOrFooter(!tablet.greaterThan)}
    </Page>
  );
};

export { PatientSignUpPage };
