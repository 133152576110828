import type { SerializedStyles } from "@emotion/serialize";

import { Spacer } from "@aviary";
import { useSearchParams } from "@shared/react-router-dom";
import type { PatientAdditionalAttributesType } from "@shared/types/PatientAdditionalAttributesType";
import type { PractitionerAdditionalAttributesType } from "@shared/types/PractitionerAdditionalAttributesType";

import { AppleSSOButton } from "./AppleSSOButton/AppleSSOButton";
import { GoogleSSOButton } from "./GoogleSSOButton/GoogleSSOButton";

interface Props {
  commonSSOProps?: SSOButtonProps;
}

interface SSOButtonProps {
  treatmentPlanSlug?: string;
  labRecommendationId?: string;
  storeSlug?: string;
  pracTypeId?: string;
  signUpPage?: string;
  pageVersion?: string;
  disabled?: boolean;
  additionalAttributes?: PractitionerAdditionalAttributesType & PatientAdditionalAttributesType;
  uuid?: string;
  buttonStyles?: SerializedStyles;
  orStyles?: SerializedStyles;
}

const LoginOptions = ({ commonSSOProps }: Props) => {
  const [searchParams] = useSearchParams();
  const isIntake = searchParams.get("intake") === "true";
  const requestedPractitionerId = searchParams.get("requestedPractitionerId");

  return (
    <>
      <GoogleSSOButton
        {...commonSSOProps}
        isIntake={isIntake}
        requestedPractitionerId={requestedPractitionerId}
      />
      <Spacer height="one" />
      <AppleSSOButton
        {...commonSSOProps}
        isOrTextRendered
        isIntake={isIntake}
        requestedPractitionerId={requestedPractitionerId}
      />
    </>
  );
};

export { LoginOptions };
