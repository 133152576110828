import { css } from "@emotion/react";

export const appleButton = css`
  margin-top: 1rem;
`;

export const orWrapper = css`
  text-align: center;
  width: 100%;
  align-content: center;
`;
