import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";

import { useOfficeSignUpMutation } from "./OfficeSignUp.mutation";

type SubmitValues = {
  password: string;
  invitationToken: string;
};

type Input = {
  setSignUpError: (error: string) => void;
};

const useSubmitOfficeSignUp = ({ setSignUpError }: Input) => {
  const [signUp, { loading, data }] = useOfficeSignUpMutation({
    onCompleted: payload => {
      if (payload?.auth?.officeSignUp?.redirectPath) {
        window.location.assign(payload.auth.officeSignUp.redirectPath);
      }
      if (payload?.auth?.officeSignUp?.errors?.message) {
        setSignUpError(payload.auth.officeSignUp.errors.message);
      }
    },
  });

  const onFormSubmit = (values: SubmitValues) => {
    const { ...formAttributes } = values;
    const attributes = { ...formAttributes, signUpPage: "office_sign_up", pageVersion: "react" };

    gRecaptchaExecute(gToken => {
      signUp({ variables: { input: { attributes, captchaToken: gToken } } });
    });
  };

  return { loading, data, onFormSubmit };
};

export { useSubmitOfficeSignUp };
