import { InMemoryCache } from "@apollo/client";

import { possibleTypes } from "@shared/data/client/possibleTypes";

const getCache = () =>
  new InMemoryCache({
    possibleTypes,
  });

export { getCache };
