import { useTranslation } from "react-i18next";

import { Column, Columns, Link, Typography } from "@aviary";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useCallistoPracOnboardingExperiment } from "@shared/hooks/useCallistoPracOnboardingExperiment/useCallistoPracOnboardingExperiment";
import { useUnauthedExperiment } from "@shared/hooks/useExperiment/useUnauthedExperiment";
import { useFlippers } from "@shared/hooks/useFlippers/useFlippers";
import { useLocation, useSearchParams } from "@shared/react-router-dom";
import type { PractitionerAdditionalAttributesType } from "@shared/types/PractitionerAdditionalAttributesType";
import { SignUpCarouselOrFooter } from "@unauthenticated/shared/components";
import { SignUpPageRefresh } from "@unauthenticated/shared/components/SimplifySignUp/SignUpPageRefresh";
import { SimplifySignUp } from "@unauthenticated/shared/components/SimplifySignUp/SimplifySignUp";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import type { PractitionerSignUpMutationData } from "@unauthenticated/shared/data/mutations/PractitionerSignUp.mutation";
import { l } from "@unauthenticated/shared/locales/i18n";
import { PageContent, Page } from "@unauthenticated/signupSignin/components";

import { AuthFooter as PractitionerOnboardingAuthFooter } from "./AuthFooter/AuthFooter";
import { AuthHeader as PractitionerOnboardingAuthHeader } from "./AuthHeader/AuthHeader";

import * as styles from "./PractitionerSignUpPage.styles";

interface Props {
  additionalAttributes?: PractitionerAdditionalAttributesType;
  csuid: string;
  ssoErrors: string;
}

const PractitionerSignUpPage = ({ additionalAttributes, csuid, ssoErrors }: Props) => {
  const { t } = useTranslation();
  const { tablet, desktop } = useBreakpoints();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const isMobile = desktop.lessThan;

  const handleOnCompleted = (data: PractitionerSignUpMutationData) => {
    if (data.auth.practitionerSignUp.redirectPath) {
      window.location.assign(data.auth.practitionerSignUp.redirectPath);
    }
  };

  const [isSignUpPageRefreshEnabled] = useFlippers(
    "polaris_sign_up_page_refresh_2023Q3_experiment"
  );
  const isPracOnboardingEnabled = useCallistoPracOnboardingExperiment();

  const group = useUnauthedExperiment({
    experiment: "signUpRefreshPolaris",
    groups: ["control", "signUpPageRefresh"],
    shouldLog: isSignUpPageRefreshEnabled,
  });

  const isSignUpRefresh =
    isSignUpPageRefreshEnabled && group === "signUpPageRefresh" && !isMobile && step === "1";

  const renderSignupRefresh = () => {
    if (!isSignUpRefresh) return null;

    return (
      <Column columnWidth={3} css={styles.videoContainer} textAlign="center">
        <SignUpPageRefresh />
      </Column>
    );
  };

  const renderHeader = () => {
    if (isPracOnboardingEnabled) {
      return <PractitionerOnboardingAuthHeader />;
    }

    return <AuthHeader />;
  };

  const renderSignInLink = () => {
    if (tablet.lessThan) return null;

    return (
      <Typography isMarginless>
        {t(l.common.HaveAcount)}{" "}
        <Link isColor="success" to={{ pathname: authRoutes.login, search }}>
          {t(l.common.SignIn)}
        </Link>
      </Typography>
    );
  };

  const renderFooter = () => {
    if (isPracOnboardingEnabled) {
      return <PractitionerOnboardingAuthFooter />;
    }

    return <SignUpCarouselOrFooter isWithinViewport={!tablet.greaterThan} hideFooterSignIn />;
  };

  return (
    <Page data-e2e="sign-up-page">
      <Columns data-e2e="sign-up-page-refresh" css={styles.marginRemoved}>
        {renderSignupRefresh()}
        <Column columnWidth={isSignUpRefresh ? 9 : 12} css={styles.mainPageColumn} isPaddingless>
          <AuthHeaderWrapper includeBorder>
            {renderHeader()}
            {renderSignInLink()}
          </AuthHeaderWrapper>
          <PageContent>
            <SimplifySignUp
              csuid={csuid}
              onCompleted={handleOnCompleted}
              additionalAttributes={additionalAttributes}
              ssoErrors={ssoErrors}
            />
          </PageContent>
          {renderFooter()}
        </Column>
      </Columns>
    </Page>
  );
};

export { PractitionerSignUpPage };
