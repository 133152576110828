import { Spacer, Skeleton, Columns, Column } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";

import * as styles from "./SignInForm.styles";

const SkeletonSignInForm = () => {
  const { tablet } = useBreakpoints();

  return (
    <>
      <Skeleton type="button" isFullWidth css={styles.marginBottom} />
      <Skeleton type="button" isFullWidth css={styles.marginBottom} />
      <div css={styles.row}>
        <Skeleton type="typography" typographySize="body" margin="topAndBottom" />
      </div>
      <Spacer height="one" />
      <Columns css={styles.row}>
        {tablet.greaterThan && (
          <Column isPaddingless preserveMobileColumns columnWidth={6} css={styles.flex}>
            <Skeleton type="button" width={1} height={1} />
            <Spacer width="half" />
            <Skeleton type="typography" typographySize="body" />
          </Column>
        )}
        <Skeleton type="button" isFullWidth />
      </Columns>
    </>
  );
};

export { SkeletonSignInForm };
