import { Spacer, Skeleton } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";

import * as styles from "./EditPasswordForm.styles";

const SkeletonEditPasswordForm = () => {
  const { tablet } = useBreakpoints();

  return (
    <>
      <Skeleton type="button" isFullWidth />
      <Spacer height="one" />
      <Skeleton type="button" isFullWidth />
      <Spacer height="one" />
      <div css={styles.alignCenter}>
        <Skeleton type="button" isFullWidth={!tablet.greaterThan} />
      </div>
    </>
  );
};

export { SkeletonEditPasswordForm };
