import { Trans, useTranslation } from "react-i18next";

import { Checkbox, Link } from "@aviary";
import { sharedRoutes } from "@shared/data/sharedRoutes";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useCallistoPracOnboardingExperiment } from "@shared/hooks/useCallistoPracOnboardingExperiment/useCallistoPracOnboardingExperiment";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./TermsOfServiceCheckbox.styles";

interface Props {
  updateTimestamp: (date: Date | "") => void;
  isChecked: boolean;
}

const TermsOfServiceCheckbox = ({ updateTimestamp, isChecked }: Props) => {
  const { isEmerson } = useSharedGlobalConfig();
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();
  const isPracOnboardingEnabled = useCallistoPracOnboardingExperiment();

  const platform = isEmerson ? t(l.common.EmersonEcologics) : t(l.common.Fullscript);
  const route = isEmerson ? sharedRoutes.external.emersonTerms : sharedRoutes.external;
  const terms = isEmerson ? t(l.common.termsOfSale) : t(l.common.termsOfServices);
  const privacy = isEmerson ? t(l.common.privacyPolicy) : t(l.common.privacyStatement);

  const handleCheckboxChange = e => {
    if (e.target.checked) {
      const date = new Date();
      updateTimestamp(date);
    } else {
      updateTimestamp("");
    }
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (!e.target.checked) {
        const date = new Date();
        updateTimestamp(date);
      } else {
        updateTimestamp("");
      }
    }
  };

  return (
    <Checkbox
      data-e2e="tos_checkbox"
      onChange={handleCheckboxChange}
      onKeyDown={handleKeyDown}
      defaultChecked={isChecked}
      key={isChecked.toString()}
      isFullWidth={isPracOnboardingEnabled}
      labelStyle={tablet.lessThan && styles.newLabelStyle}
    >
      <Trans i18nKey={l.common.Terms} values={{ platform, terms, privacy }} shouldUnescape>
        I agree to {{ platform }}
        <Link href={route.termsOfService} noExternalIcon>
          Terms of Service
        </Link>
        and
        <Link href={route.privacyPolicy} noExternalIcon>
          Privacy Statement
        </Link>
      </Trans>
    </Checkbox>
  );
};

export { TermsOfServiceCheckbox };
