import { Trans, useTranslation } from "react-i18next";

import { Column, Typography, Spacer } from "@aviary";
import { AuthFooter } from "@shared/components/AuthFooter/AuthFooter";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import { FormWrapper } from "@unauthenticated/shared/components";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { PageContent, Page, TakeMeToGmailButton } from "@unauthenticated/signupSignin/components";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import EmailSVGComponent from "./svg/Email";

import * as styles from "./EmailSentPage.styles";

interface Props {
  email: string;
}

const EmailSentPage = ({ email }: Props) => {
  const { t } = useTranslation();

  return (
    <Page>
      <PageContent>
        <AuthHeaderWrapper>
          <AuthHeader route={authRoutes.login} />
        </AuthHeaderWrapper>
        <FormWrapper>
          <div css={styles.center}>
            <Typography isMarginless type="h3">
              {t(l.practitionerSignUp.PleaseCheckInbox)}
            </Typography>
            <Spacer height="double" />
            <EmailSVGComponent />
            <Spacer height="doubleHalf" />
            <Column textAlign="center">
              <Typography isMarginless type="h3">
                <Trans i18nKey={l.signIn.EmailHasBeenSent}>
                  An email has been sent to {{ email }}.
                </Trans>
              </Typography>
              <Spacer height="double" />
              <Typography>{t(l.practitionerSignUp.ifAddedByPract)}</Typography>
              <Spacer height="doubleHalf" />
            </Column>
            <TakeMeToGmailButton email={email} />
          </div>
        </FormWrapper>
      </PageContent>
      <AuthFooter />
    </Page>
  );
};

export { EmailSentPage };
