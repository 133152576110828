import type { SyntheticEvent } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Arrange, Link, Typography, useToast } from "@aviary";
import FsEeDualLogoIcon from "@shared/assets/images/fs-ee-dual-logo-icon.svg";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useDebounce } from "@shared/hooks/useDebounce/useDebounce";
import type { StorePlatform } from "@shared/types/StorePlatform";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import {
  useRequestSharedAccountVerification,
  type RequestSharedAccountVerificationEmailData,
} from "@unauthenticated/signupSignin/data/mutations";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./LinkNotificationPage.styles";

interface Props {
  email: string;
  storePlatform: StorePlatform;
}

const LinkNotificationPage = ({ email, storePlatform }: Props) => {
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();
  const isDesktop = tablet.greaterThan;
  const [requestSharedAccountVerification] = useRequestSharedAccountVerification();

  const query = () => {
    gRecaptchaExecute(gToken => {
      requestSharedAccountVerification({
        variables: {
          input: {
            email,
            requestedPlatform: storePlatform,
            captchaToken: gToken,
          },
        },
        onCompleted,
      });
    });
  };

  const onCompleted = (data: RequestSharedAccountVerificationEmailData) => {
    ShowMessage(data.auth?.requestSharedAccountVerificationEmail.sent ? "success" : "error");
  };
  const sendEmail = (e?: SyntheticEvent) => {
    e.preventDefault();
    query();
  };

  const handleReSendLink = useDebounce(sendEmail, 500);
  const { makeToast } = useToast();
  const ShowMessage = (type: "success" | "error") => {
    const notificationType =
      type === "success" ? l.linkNotification.linkSent : l.linkNotification.linkError;

    makeToast(type, <>{t(notificationType)}</>);
  };

  const platform = () => (storePlatform === "Fullscript" ? "fs" : "ee");

  const renderResendLink = () => (
    <Link onClick={handleReSendLink} css={styles.resentLink} data-testid="resend-link">
      {t(l.linkNotification.resend)}
    </Link>
  );

  return (
    <>
      <AuthHeaderWrapper includeBorder>
        <AuthHeader
          route={storePlatform === "Fullscript" ? authRoutes.login : authRoutes.emerson.login}
          showFromEmerson={storePlatform === "Emerson"}
        />
      </AuthHeaderWrapper>
      <div css={styles.mainContainer}>
        <div css={styles.headerContainer}>
          <div>
            <Typography type={isDesktop ? "h1" : "h3"}>{t(l.linkNotification.title)}</Typography>
            <Typography type={isDesktop ? "h4" : "body"} css={styles.semiBold}>
              {t(l.linkNotification.pleaseCheck[platform()])}
            </Typography>
          </div>
          <img
            css={styles.dualLogoIcon}
            src={FsEeDualLogoIcon}
            alt="Fullscript Emerson Ecologics logo"
          />
          <Typography type={isDesktop ? "h4" : "body"} css={styles.semiBold}>
            {t(l.linkNotification.subTitle)}
          </Typography>
        </div>

        <Arrange isVertical spacing="xlarge" alignment="center">
          <div css={styles.descriptionContainer}>
            <Trans i18nKey={l.linkNotification.existingEmail[platform()]}>
              You have an existing {{ storePlatform }} account with the email
              <span>{{ email }}.</span>
            </Trans>
            <Typography type="body">{t(l.linkNotification.emailSent[platform()])}</Typography>
          </div>
          <Typography type="body">
            <Trans
              i18nKey={l.linkNotification.didntGetEmail}
              components={{
                1: renderResendLink(),
              }}
            >
              {renderResendLink()}
            </Trans>
          </Typography>
          <div>
            &lt;{" "}
            <Link
              to={
                storePlatform === "Fullscript"
                  ? authRoutes.practitioner_sign_up
                  : authRoutes.emerson.sign_up
              }
            >
              {t(l.linkNotification.returnToSignUp)}
            </Link>
          </div>
        </Arrange>
      </div>
    </>
  );
};

export { LinkNotificationPage };
