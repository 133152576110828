import { useTranslation } from "react-i18next";

import { Arrange } from "@aviary";
import { SvgHipaaLogoPrimary } from "@shared/assets/logo";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@shared/locales/i18n";

import * as styles from "./FooterLogos.styles";

const FooterLogos = () => {
  const { t } = useTranslation();
  const { isUS, isEmerson } = useSharedGlobalConfig();

  return (
    <Arrange isWrapped={false} spacing="none" justify="start">
      {isUS && !isEmerson && (
        <SvgHipaaLogoPrimary
          css={styles.spacingLeft}
          aria-label={t(l.aviary.footer.HIPAAalttext)}
        />
      )}
    </Arrange>
  );
};

export { FooterLogos };
