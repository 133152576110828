import type { NormalizedCacheObject } from "@apollo/client";
import { ApolloClient, ApolloLink } from "@apollo/client";
import { useMemo } from "react";

import { getErrorLink } from "@shared/data/client/links/errorLink/errorLink";
import { getAddUTMLink } from "@shared/data/client/links/getAddUTMLink";
import { getFrontendMetaDataLink } from "@shared/data/client/links/getFrontendMetaDataLink/getFrontendMetaDataLink";
import { getPersistedQueriesLink } from "@shared/data/client/links/getPersistedQueriesLink/getPersistedQueriesLink";
import { getQueryLoadingLink } from "@shared/data/client/links/getQueryLoadingLink/getQueryLoadingLink";
import { getUploadLink } from "@shared/data/client/links/uploadLink";
import { useFlippers } from "@shared/hooks/useFlippers/useFlippers";
import { isProduction, isDevelopment } from "@shared/utils/fullscriptEnv/fullscriptEnv";

import { getCache } from "./cache";

const useClient = (): ApolloClient<NormalizedCacheObject> => {
  const [gqlPersistedQueriesFlipper] = useFlippers("gql_persisted_queries");

  const getClient = () => {
    const getLink = () => {
      const errorLink = getErrorLink(null, true);
      const uploadLink = getUploadLink();

      let links = [errorLink, getFrontendMetaDataLink(), getAddUTMLink()];
      if (gqlPersistedQueriesFlipper) {
        const persistedQueriesLink = getPersistedQueriesLink();
        links.push(persistedQueriesLink);
      }
      if (isDevelopment()) {
        const queryLoadingLink = getQueryLoadingLink();
        links = [...links, queryLoadingLink];
      }

      return ApolloLink.from([...links, uploadLink]);
    };

    const cache = getCache();

    return new ApolloClient({
      link: getLink(),
      cache,
      connectToDevTools: !isProduction(),
    });
  };

  return useMemo(getClient, [gqlPersistedQueriesFlipper]);
};

export { useClient };
