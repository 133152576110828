import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";

import { useOfficeActivateAccountMutation } from "./AuthOfficeActivateAccount.mutation";

type SubmitValues = {
  invitationToken: string;
};

type Input = {
  setSignUpError: (error: string) => void;
};

const useOfficeActivateAccount = ({ setSignUpError }: Input) => {
  const [signUp, { loading, data }] = useOfficeActivateAccountMutation({
    onCompleted: payload => {
      if (payload?.auth?.officeActivateAccount?.redirectPath) {
        window.location.assign(payload.auth.officeActivateAccount.redirectPath);
      }
      if (payload?.auth?.officeActivateAccount?.errors?.message) {
        setSignUpError(payload.auth.officeActivateAccount.errors.message);
      }
    },
  });

  const onFormSubmit = (values: SubmitValues) => {
    const { ...formAttributes } = values;
    const attributes = { ...formAttributes };

    gRecaptchaExecute(gToken => {
      signUp({ variables: { input: { attributes, captchaToken: gToken } } });
    });
  };

  return { loading, data, onFormSubmit };
};

export { useOfficeActivateAccount };
