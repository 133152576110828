import { withProfiler } from "@sentry/react";
import { StrictMode } from "react";

import { LazyHotReloadIndicator } from "@shared/components/LazyHotReloadIndicator/LazyHotReloadIndicator";
import { LazyReactAxe } from "@shared/components/LazyReactAxe/LazyReactAxe";
import { RouterProvider, createBrowserRouter } from "@shared/react-router-dom";

import { getFullscriptRoutes, type Props } from "./routes";

const AuthLandingPage = (props: Props) => {
  const pageVersion = "prac_by_default_with_sso";

  return (
    <StrictMode>
      <RouterProvider
        router={createBrowserRouter(getFullscriptRoutes({ ...props, pageVersion }))}
      />
      <LazyHotReloadIndicator />
      <LazyReactAxe />
    </StrictMode>
  );
};

const AuthLandingPageWithProfiler = withProfiler(AuthLandingPage);
export { AuthLandingPageWithProfiler as AuthLandingPage };
