import { useState, type SyntheticEvent } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Illustration, Link, Message, Spacer, Typography } from "@aviary";
import { BackButton } from "@aviary/layouts";
import { useNavigate, useSearchParams } from "@shared/react-router-dom";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import {
  useUserSendMagicLinkMutation,
  type UserSendMagicLinkMutationData,
} from "@unauthenticated/shared/data/mutations";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./SuccessContent.styles";

interface Props {
  email: string;
  setPage: (page: "email" | "success") => void;
  isSwiftFillSignIn?: boolean;
}

const SuccessContent = ({ email, setPage, isSwiftFillSignIn }: Props) => {
  const { t } = useTranslation();
  const [showResendSuccess, setShowResendSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const storeId = searchParams?.get("store_id");
  const redirectPath = searchParams?.get("redirect_path");
  const intake = searchParams?.get("intake") === "true";
  const onCompleted = (data: UserSendMagicLinkMutationData) => {
    const { message: errorMessage } = data?.auth?.userSendMagicLink?.errors ?? {};

    if (!errorMessage) {
      setShowResendSuccess(true);
    }
  };

  const [sendMagicLink] = useUserSendMagicLinkMutation();
  const handleSendLink = (e: SyntheticEvent) => {
    e.preventDefault();

    gRecaptchaExecute(gToken => {
      sendMagicLink({
        variables: {
          input: {
            captchaToken: gToken,
            email,
            intake,
            ...(storeId && { storeId }),
            ...(redirectPath && { redirectPath }),
          },
        },
        onCompleted,
      });
    });
  };

  const backButton = () => {
    if (isSwiftFillSignIn) {
      return <BackButton onClick={() => navigate(-1)} text={t(l.auth.back)} isUnderLined={false} />;
    }

    return (
      <BackButton onClick={() => setPage("email")} text={t(l.auth.back)} isUnderLined={false} />
    );
  };

  const handleDifferentSignInOption = (e: SyntheticEvent) => {
    e.preventDefault();

    if (isSwiftFillSignIn) return navigate(-1);

    return navigate(authRoutes.login);
  };

  return (
    <>
      {showResendSuccess && (
        <Message isColor="success">
          {t(l.signIn.continueWithoutPassword.successPage.resentSuccess)}
        </Message>
      )}

      <div css={styles.textWrapper}>
        <Typography type="h2">{t(l.signIn.continueWithoutPassword.successPage.title)}</Typography>
        <Typography type="h3">
          {t(l.signIn.continueWithoutPassword.successPage.subTitle)}
        </Typography>
        <Spacer height="oneHalf" />
        <Illustration illustration="envelope" heightAndWidth={12} />
        <Spacer height="oneHalf" />
        <Typography type="body">
          <Trans
            i18nKey={l.signIn.continueWithoutPassword.successPage.emailSent}
            values={{ email }}
            components={{
              emailText: <strong />,
            }}
          />
        </Typography>
        <Spacer height="one" />
        <Typography>
          <Trans
            i18nKey={l.signIn.continueWithoutPassword.successPage.DidNotGetEmail}
            components={{
              resendLink: <Link onClick={handleSendLink} />,
              signInLink: <Link onClick={handleDifferentSignInOption} />,
            }}
          />
        </Typography>
      </div>

      {backButton()}
    </>
  );
};

export { SuccessContent };
