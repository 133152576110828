import { cookieExtractor } from "@shared/helpers/cookieExtractor/cookieExtractor";
import type { RouteObject } from "@shared/react-router-dom";
import type { Flipper } from "@shared/types/flipper";
import { wildcardPath } from "@shared/utils/wildcardPath/wildcardPath";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { ContinueWithoutPasswordPage } from "@unauthenticated/signupSignin/scenes/ContinueWithoutPasswordPage";
import { LazyEditPasswordPage } from "@unauthenticated/signupSignin/scenes/EditPasswordPage";
import { LazyForgotPasswordPage } from "@unauthenticated/signupSignin/scenes/ForgotPasswordPage";
import { PatientSignUpPage } from "@unauthenticated/signupSignin/scenes/PatientSignUpPage";
import { PractitionerSignUpPage } from "@unauthenticated/signupSignin/scenes/PractitionerSignUpPage";
import { LazySignInPage } from "@unauthenticated/signupSignin/scenes/SignInPage";

import { Root, type Props as RootProps } from "./Root";

type Route = RouteObject & {
  flipper?: Flipper;
};

interface Props extends RootProps {
  signUpAdditionalAttributes?: any;
  signInAdditionalAttributes?: any;
  ssoErrors?: string;
  authErrors?: string;
}

const fullscriptRoutes = ({
  authErrors,
  csuid,
  pageVersion,
  signInAdditionalAttributes,
  signUpAdditionalAttributes,
  ssoErrors,
}: Props): Route[] => [
  {
    path: wildcardPath(authRoutes.edit_password),
    element: <LazyEditPasswordPage />,
  },
  {
    path: wildcardPath(authRoutes.forgot_password),
    element: <LazyForgotPasswordPage />,
  },
  {
    path: wildcardPath(authRoutes.login),
    element: (
      <LazySignInPage
        ssoErrors={ssoErrors}
        authErrors={authErrors}
        additionalAttributes={signInAdditionalAttributes}
      />
    ),
  },
  {
    path: wildcardPath(authRoutes.continue_without_password),
    element: <ContinueWithoutPasswordPage />,
  },
  {
    path: wildcardPath(authRoutes.patient_sign_up),
    element: <PatientSignUpPage {...{ csuid, pageVersion }} />,
  },
  {
    path: wildcardPath(authRoutes.practitioner_sign_up),
    element: (
      <PractitionerSignUpPage
        {...{
          additionalAttributes: signUpAdditionalAttributes,
          csuid,
          ssoErrors,
        }}
      />
    ),
  },
];

const routeActive = (route: Route, flippers: Flipper[]) => {
  return !route.flipper || flippers.includes(route.flipper);
};

const activeRoutes = (allRoutes: Route[], flippers: Flipper[]) => {
  return allRoutes
    .filter(route => routeActive(route, flippers))
    .map(({ flipper: unusedFlipper, ...route }) => route);
};

const getFullscriptRoutes = (props: Props): Route[] => {
  const { _gid: googleId, _ga: googleAnalyticsId } = cookieExtractor(["_gid", "_ga"]);

  const optionalAttributesForSignUp = {
    ...props.signUpAdditionalAttributes,
    googleId,
    googleAnalyticsId,
  };

  const allRoutes = fullscriptRoutes({
    ...props,
    signUpAdditionalAttributes: optionalAttributesForSignUp,
  });

  return [
    {
      path: "/",
      element: <Root {...{ ...props, signUpAdditionalAttributes: optionalAttributesForSignUp }} />,
      children: activeRoutes(allRoutes, props.flippers),
    },
  ];
};

export type { Route, Props };
export { getFullscriptRoutes };
