import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Arrange, Typography } from "@aviary";
import FullscriptLogoGreenLeafPigeonTextSVGComponent from "@shared/assets/logo/FullscriptLogoGreenLeafPigeonText";
import { AppleSSOButton } from "@shared/components/LoginOptions/AppleSSOButton/AppleSSOButton";
import { GoogleSSOButton } from "@shared/components/LoginOptions/GoogleSSOButton/GoogleSSOButton";
import { e2eAttributes } from "@shared/e2eAttributes";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { FormWrapper, ErrorBox, SignInWrapper } from "@unauthenticated/shared/components";
import { SSOErrorBox } from "@unauthenticated/shared/components/SSOErrorBox/SSOErrorBox";
import {
  StoreLogo,
  SignUpContainer,
  SignUpHeader,
  SignUpTitle,
} from "@unauthenticated/signupSignin/components";
import { Page } from "@unauthenticated/signupSignin/components/Page";
import { StoreLandingFooter } from "@unauthenticated/signupSignin/components/StoreLandingFooter";
import type { RoleAttributes } from "@unauthenticated/signupSignin/data/types";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import { AuthOfficeActivationForm } from "./AuthOfficeActivationForm";
import { OfficeSignUpForm } from "./OfficeSignUpForm";
import { useLogOfficeSignUp } from "./useLogOfficeSignUp";

import * as styles from "./OfficeSignUpPage.styles";

interface Props {
  roleAttributes: RoleAttributes;
  roleType: "Clerk" | "Practitioner";
  roleId: string;
  ssoErrors?: string;
  isEmersonUser?: boolean;
}

const OfficeSignUpPage = ({ roleAttributes, roleId, ssoErrors, isEmersonUser }: Props) => {
  const { t } = useTranslation();
  const { firstName, lastName, storeName, storeLogo, invitationToken } = roleAttributes;
  const [signUpError, setSignUpError] = useState("");
  const pageVersion = "react_with_sso";
  const signUpPage = "office_sign_up_page";
  const { tablet } = useBreakpoints();
  const isDesktop = tablet.greaterThan;

  const { logField } = useLogOfficeSignUp({
    eventAggregateId: roleId,
    signUpPage,
    pageVersion,
  });

  const RenderSignInForm = () => {
    if (isEmersonUser)
      return (
        <SignInWrapper>
          <AuthOfficeActivationForm
            invitationToken={invitationToken}
            setSignUpError={setSignUpError}
          />
        </SignInWrapper>
      );

    return (
      <SignInWrapper>
        <Arrange isVertical alignment="center" isFullWidth>
          <GoogleSSOButton signUpPage={signUpPage} pageVersion={pageVersion} />
          <AppleSSOButton isOrTextRendered signUpPage={signUpPage} pageVersion={pageVersion} />
        </Arrange>

        <OfficeSignUpForm
          logField={logField}
          invitationToken={invitationToken}
          setSignUpError={setSignUpError}
        />
      </SignInWrapper>
    );
  };

  return (
    <Page>
      <div css={styles.layoutContainer} data-e2e={e2eAttributes.officeSignUp.modal}>
        <Arrange isFullWidth isFullHeight isWrapped={!isDesktop} css={styles.fullHeight}>
          <Arrange
            isFullWidth
            isFullHeight
            isVertical
            justify="center"
            alignment="center"
            css={styles.leftColumn}
          >
            <FullscriptLogoGreenLeafPigeonTextSVGComponent css={styles.svgContainer} />
            <Typography type="h4">
              {t(l.officeSignUp.SignUpWelcomeMessage, {
                firstName,
                lastName,
                storeName,
              })}
            </Typography>
          </Arrange>

          <Arrange
            isFullWidth
            isFullHeight
            isVertical
            justify="center"
            alignment="center"
            css={styles.rightColumn}
          >
            <SignUpHeader>
              {isDesktop && <StoreLogo storeLogo={storeLogo} storeName={storeName} />}
              <SignUpTitle title={storeName} />
            </SignUpHeader>
            <SignUpContainer>
              <FormWrapper css={styles.formWrapperOverride}>
                <SSOErrorBox ssoErrors={ssoErrors} />
                {!!signUpError && <ErrorBox error={signUpError} />}
                {RenderSignInForm()}
              </FormWrapper>
            </SignUpContainer>
          </Arrange>
        </Arrange>
      </div>
      <StoreLandingFooter />
    </Page>
  );
};

export { OfficeSignUpPage };
