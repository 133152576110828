import type { SyntheticEvent } from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Link, Typography } from "@aviary";
import { EmailInput } from "@shared/components/EmailInput/EmailInput";
import {
  isEmailValid,
  isEmailDotValid,
} from "@shared/components/EmailInput/EmailValidation/EmailValidation";
import { AppleSSOButton } from "@shared/components/LoginOptions/AppleSSOButton/AppleSSOButton";
import { GoogleSSOButton } from "@shared/components/LoginOptions/GoogleSSOButton/GoogleSSOButton";
import { useLocation, useNavigate } from "@shared/react-router-dom";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import {
  FormWrapper,
  IsNameValid,
  isPasswordValid,
  PasswordInput,
  TermsOfService,
} from "@unauthenticated/shared/components";
import {
  FirstNameInput,
  LastNameInput,
} from "@unauthenticated/shared/components/PractitionerSignUpForm";
import { SSOErrorBox } from "@unauthenticated/shared/components/SSOErrorBox/SSOErrorBox";
import { useGlobalConfig } from "@unauthenticated/shared/context";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import type { PatientSignUpMutationData } from "@unauthenticated/shared/data/mutations/PatientSignUp.mutation";
import { usePatientSignUpMutation } from "@unauthenticated/shared/data/mutations/PatientSignUp.mutation";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./PatientStoreSignUpForm.styles";

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  pageVersion?: string;
  storeId?: string;
  isPreview?: boolean;
  ssoErrors?: string;
  preFilledEmail?: string;
  isSwiftFillSignIn?: boolean;
  isIntake?: boolean;
  requestedPractitionerId?: string;
}

const PatientStoreSignUpForm = ({
  patientAttributes,
  additionalAttributes,
  pageVersion,
  storeId,
  isPreview,
  ssoErrors,
  preFilledEmail = "",
  isSwiftFillSignIn,
  isIntake,
  requestedPractitionerId,
}: Props) => {
  const { t } = useTranslation("common");
  const { emailRegex } = useGlobalConfig();
  const { storeSlug, treatmentPlanSlug, uuid, labRecommendationId } = patientAttributes;

  const SIGN_UP_PAGE = "rx_patient_sign_up_page";

  const navigate = useNavigate();
  const searchParams = useLocation().search;

  const [firstName, setFirstName] = useState("");
  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameDirty, setLastNameDirty] = useState(false);
  const [email, setEmail] = useState(isSwiftFillSignIn ? preFilledEmail : "");
  const [emailDirty, setEmailDirty] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordDirty, setPasswordDirty] = useState(false);

  const [signUp, { loading, data }] = usePatientSignUpMutation({
    onCompleted: (payload: PatientSignUpMutationData) => {
      const errorMessage =
        payload?.auth?.patientSignUp?.errors?.message ||
        payload?.auth?.patientSignUp?.errors?.fields;
      if (!errorMessage) {
        window.location.assign(payload?.auth?.patientSignUp?.redirectPath ?? authRoutes.base);
      }
    },
  });

  const {
    firstName: firstNameError,
    lastName: lastNameError,
    email: emailError,
    password: passwordError,
  } = data?.auth?.patientSignUp?.errors?.fields ?? {};

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    gRecaptchaExecute(gToken => {
      signUp({
        variables: {
          input: {
            attributes: {
              firstName,
              lastName,
              email,
              password,
              storeId,
              optional: additionalAttributes,
              intake: isIntake,
              requestedPractitionerId,
            },
            captchaToken: gToken,
          },
        },
      });
    });
  };

  const areFieldsValid = () => {
    const passwordValid = isPasswordValid(password);
    const firstNameValid = IsNameValid(firstName);
    const lastNameValid = IsNameValid(lastName);
    const emailValid = isEmailValid(email, emailRegex);

    return passwordValid && firstNameValid && lastNameValid && emailValid;
  };

  const handleEmailInputBlur = e => {
    setEmailDirty(true);
    if (e?.target.value) {
      setEmail(e.target.value);
    }
  };

  const getEmailError = () => {
    if (!isEmailDotValid(email)) {
      return t(l.common.EmailMustHaveDot);
    }
    if (!emailRegex.test(email)) {
      return t(l.common.EmailInvalidFormat);
    }
  };

  const handleOnSignInClick = () => {
    navigate(`${authRoutes.welcomeStart(storeSlug, uuid ?? treatmentPlanSlug)}${searchParams}`);
  };

  const getPasswordError = () => {
    if (passwordError) return passwordError;
    const { message } = data?.auth?.patientSignUp?.errors ?? {};
    if (message?.includes("in use")) {
      const forgotLink = `${authRoutes.forgot_password}`;
      const newErrorMessage = (
        <Trans i18nKey={l.common.emailInUse}>
          This email is already in use. Try
          <Link onClick={handleOnSignInClick}>sign in</Link>
          or
          <Link href={forgotLink}>forgot password</Link>
          instead
        </Trans>
      );

      return [newErrorMessage];
    }
    if (message) return [message];

    return null;
  };

  return (
    <FormWrapper css={styles.formWrapper}>
      <SSOErrorBox ssoErrors={ssoErrors} />
      <GoogleSSOButton
        pageVersion={pageVersion}
        signUpPage={SIGN_UP_PAGE}
        additionalAttributes={additionalAttributes}
        disabled={isPreview}
        labRecommendationId={labRecommendationId}
        uuid={uuid}
        treatmentPlanSlug={treatmentPlanSlug}
        storeSlug={storeSlug}
        requestedPractitionerId={requestedPractitionerId}
      />
      <AppleSSOButton
        css={styles.appleButton}
        isOrTextRendered
        pageVersion={pageVersion}
        signUpPage={SIGN_UP_PAGE}
        additionalAttributes={additionalAttributes}
        disabled={isPreview}
        labRecommendationId={labRecommendationId}
        uuid={uuid}
        treatmentPlanSlug={treatmentPlanSlug}
        storeSlug={storeSlug}
        requestedPractitionerId={requestedPractitionerId}
      />
      <fieldset disabled={isPreview} css={styles.fieldset}>
        <form
          onSubmit={handleSubmit}
          css={styles.form}
          noValidate
          data-e2e="patient-sign-up-form"
          data-gtm-id="patient-sign-up-form"
        >
          <div css={styles.Row}>
            <FirstNameInput
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              wrapperStyles={styles.margin}
              required
              isDirty={firstNameDirty}
              onBlur={() => {
                setFirstNameDirty(true);
              }}
              errors={firstNameError}
              isLoading={loading}
            />
            <LastNameInput
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              required
              isDirty={lastNameDirty}
              onBlur={() => {
                setLastNameDirty(true);
              }}
              isLoading={loading}
              errors={lastNameError}
            />
          </div>
          <div css={styles.Row}>
            <EmailInput
              value={email}
              disabled={isSwiftFillSignIn && !!preFilledEmail}
              onChange={e => setEmail(e.target.value)}
              setEmail={setEmail}
              required
              isDirty={emailDirty}
              onBlur={handleEmailInputBlur}
              isLoading={loading}
              error={getEmailError() ?? emailError?.[0]}
              hideSuggestion
            />
          </div>
          <div css={styles.Row}>
            <PasswordInput
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
              isDirty={passwordDirty}
              onBlur={() => setPasswordDirty(true)}
              isLoading={loading}
              errors={getPasswordError()}
            />
          </div>
          <TermsOfService css={styles.serviceTerms} />
          <div css={styles.marginBottom}>
            <Button
              data-e2e="intake-create-account-button"
              type="submit"
              disabled={!areFieldsValid()}
              isLoading={loading}
            >
              {t(l.common.CreateMyAccount)}
            </Button>
          </div>
        </form>
      </fieldset>
      <Typography sizeOverride="footnote">
        <Link data-e2e="intake-back-link" onClick={() => navigate(-1)}>
          {t(l.auth.back)}
        </Link>
      </Typography>
    </FormWrapper>
  );
};

export { PatientStoreSignUpForm };
