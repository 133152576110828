import type { KeyboardEvent, MouseEvent, ReactNode } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import type { SearchBarProps } from "@aviary";
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  SearchBar,
  Skeleton,
  SkeletonList,
} from "@aviary";
import { useDebounce } from "@shared/hooks/useDebounce/useDebounce";
import { useKeyboardNavigationList } from "@shared/hooks/useKeyboardNavigationList/useKeyboardNavigationList";
import { useOutsideClick } from "@shared/hooks/useOutsideClick/useOutsideClick";
import type { PractitionerType } from "@shared/types/graphqlGenerated";
import { l } from "@unauthenticated/shared/locales/i18n";

import { useDesignations } from "./useDesignations";

import * as styles from "./DesignationSearch.styles";

interface Props extends Pick<SearchBarProps, "onBlur"> {
  value?: PractitionerType;
  setValue: (value: PractitionerType) => void;
  required?: boolean;
}

const RESULTS_TO_SHOW = 6;

const DesignationSearch = ({ value, setValue, ...rest }: Props) => {
  const { t } = useTranslation();
  const { designations, loading } = useDesignations();
  const initialDesignation = designations?.find(element => element.id === value?.id);
  const [searchText, setSearchText] = useState(initialDesignation?.description || "");
  const [searchBarFocus, setSearchBarFocus] = useState(false);
  const [searchResults, setSearchResults] = useState<PractitionerType[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const clearField = (e: KeyboardEvent<Element> | MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearchText("");
    setValue(null);
  };

  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  });

  const onEnter = () => {
    if (searchText === "" || searchResults.length === 0) return;
    const pracType = searchResults[itemSelectedIndex];

    if (!pracType) return;

    setSearchText(pracType.description);
    setValue(pracType);
  };

  const { itemSelectedIndex, onKeyDown } = useKeyboardNavigationList(
    RESULTS_TO_SHOW,
    null,
    null,
    onEnter,
    null,
    clearField
  );

  const renderItem = (item: PractitionerType) => (
    <DropdownItem
      key={item.id}
      onSelect={() => {
        setSearchText(item.description);
        setValue(item);
        setIsDropdownOpen(false);
      }}
    >
      {item.description}
    </DropdownItem>
  );

  const renderItems = () => {
    const items = searchText === "" ? designations : searchResults;
    if (!items?.length) {
      return <DropdownItem disabled>{t(l.common.DesignationNoResults)}</DropdownItem>;
    }

    return items.map(renderItem);
  };

  const renderSkeletons = (numOfSkeletons: number): ReactNode => (
    <SkeletonList number={numOfSkeletons}>
      <Skeleton type="typography" typographySize="body" margin="top" isFullWidth />
    </SkeletonList>
  );

  const renderSearchResults = () => {
    if (loading || !designations) {
      return (
        <DropdownContent>
          <div>{renderSkeletons(RESULTS_TO_SHOW)}</div>
        </DropdownContent>
      );
    }

    return <DropdownContent>{renderItems()}</DropdownContent>;
  };

  const debounceOnSearch = useDebounce(filteredResults => setSearchResults(filteredResults), 300);

  const handleSearch = (searchValue: string) => {
    const searchValueLowerCase = searchValue.toLocaleLowerCase();

    const isDesignation = designations?.some(
      designation => designation.description.toLocaleLowerCase() === searchValueLowerCase
    );

    if (searchValue === "" || !isDesignation) {
      setValue(null);
    }

    const filteredResults = designations?.filter(designation => {
      if (searchValue) {
        return designation.description.toLocaleLowerCase().includes(searchValueLowerCase);
      }

      return true;
    });
    setIsDropdownOpen(true);
    setSearchBarFocus(true);
    setSearchText(searchValue);
    debounceOnSearch(filteredResults);
  };

  return (
    <div css={styles.dropDown} ref={dropdownRef}>
      <Dropdown
        isOpen={isDropdownOpen}
        triggerCallback={() => setIsDropdownOpen}
        customDropdownHeight={11}
        isFullWidth
      >
        <DropdownTrigger>
          <SearchBar
            incrementalSearch
            boxStyles={styles.searchBarBox}
            inputStyles={styles.searchBarInput}
            value={searchText}
            isFocus={searchBarFocus}
            onSearch={() => null}
            onChange={e => handleSearch(e.target.value)}
            onClick={() => setIsDropdownOpen(true)}
            placeholder={t(l.common.DesignationSearch)}
            onTrailingIconClick={clearField}
            onKeyDown={onKeyDown}
            {...rest}
          />
        </DropdownTrigger>
        {renderSearchResults()}
      </Dropdown>
    </div>
  );
};

export { DesignationSearch };
