import type { Theme } from "@emotion/react";
import type { SerializedStyles } from "@emotion/serialize";
import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons";
import type { ChangeEvent, HTMLProps, FocusEvent, KeyboardEvent, MouseEvent } from "react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { NoStyleButton } from "@aviary/components/Button";
import { l } from "@aviary/locales/i18n";
import { useDebounce } from "@shared/hooks/useDebounce/useDebounce";
import { FontAwesomeIcon } from "@shared/react-fontawesome";
import { Size } from "@shared/types/sizes";

import * as styles from "./SearchBar.styles";

/** @deprecated Please use TextField instead */
interface SearchBarProps extends HTMLProps<HTMLInputElement> {
  /**
   * Sets the search bar to a focused state
   *
   * @default true
   */
  isFocus?: boolean;
  /**
   * Specifies the size of the search bar
   *
   * @default Size.MEDIUM
   */
  isSize?: Size.SMALL | Size.MEDIUM;
  /**
   * Moves the search icon to the left and also debounces the search by 400ms
   *
   * @default false
   */
  incrementalSearch?: boolean;
  /**
   * Placeholder text for the search bar
   */
  placeholder: string;
  /**
   * Search value entered by user
   */
  value: string;
  /**
   * Specifies whether search icon is shown by default
   *
   * @default true
   */
  alwaysShowSearchIcon?: boolean;
  /**
   * Specifies whether close icon is shown by default. Replaces search icon
   *
   * @default false
   */
  alwaysShowTrailingIcon?: boolean;
  /**
   * Adds in custom styles to the HTML input
   */
  inputStyles?: SerializedStyles | ((theme: Theme) => SerializedStyles);
  /**
   * Adds in custom styles to the search bar box
   */
  boxStyles?: SerializedStyles | ((theme: Theme) => SerializedStyles) | SerializedStyles[];
  /**
   * Callback on blur
   */
  onBlur?: () => void;
  /**
   * Callback on change
   */
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Callback on search
   */
  onSearch: (term: string) => void;
  /**
   * Callback on close button click
   */
  onTrailingIconClick: (e?: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Callback on focus
   */
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  /**
   * Callback on key down
   */
  onKeyDown?: (e: KeyboardEvent) => void;
}

const SearchBar = ({
  placeholder = "...",
  onTrailingIconClick,
  onSearch,
  isFocus = true,
  isSize = Size.MEDIUM,
  incrementalSearch = false,
  alwaysShowSearchIcon = true,
  alwaysShowTrailingIcon,
  value,
  onKeyDown,
  disabled,
  inputStyles,
  boxStyles,
  ...rest
}: SearchBarProps) => {
  const debouncedSearch = useDebounce(term => onSearch(term), 400);
  const { t } = useTranslation();
  const inputRef = useRef(null);

  useEffect(() => {
    if (isFocus) {
      inputRef.current.focus();
    }
  }, [isFocus]);

  useEffect(() => {
    if (incrementalSearch) {
      debouncedSearch(value);

      return () => debouncedSearch.cancel();
    }
  }, [value]);

  const handleKeyDown = (e: KeyboardEvent) => {
    // Enter key
    if (e.key === "Escape" && !incrementalSearch) {
      onSearch(value);
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const renderCloseButton = () => (
    <NoStyleButton aria-label={t(l.aviary.searchBar.ClearSearch)} onClick={onTrailingIconClick}>
      <FontAwesomeIcon icon={faTimes} />
    </NoStyleButton>
  );

  const renderCloseButtonOrSearchIcon = () => {
    if (alwaysShowTrailingIcon) {
      return renderCloseButton();
    }

    if (incrementalSearch) {
      return value && renderCloseButton();
    }

    if (alwaysShowSearchIcon) {
      return (
        <NoStyleButton aria-label={t(l.aviary.searchBar.Search)} onClick={() => onSearch(value)}>
          <FontAwesomeIcon css={styles.searchIcon} icon={faSearch} />
        </NoStyleButton>
      );
    }
  };

  const containerBuilder = () => {
    return [
      styles.searchBar.base,
      styles.searchBar[isSize],
      disabled && styles.searchBar.disabled,
      boxStyles,
    ];
  };

  return (
    <div css={containerBuilder()}>
      {incrementalSearch && (
        <FontAwesomeIcon
          css={styles.searchIcon}
          icon={faSearch}
          title={t(l.aviary.searchBar.Search)}
        />
      )}
      <input
        aria-label={t(l.aviary.searchBar.SearchAriaLabel)}
        autoComplete="on"
        css={[styles.inputStyles, inputStyles]}
        type="text"
        value={value}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        disabled={disabled}
        placeholder={placeholder}
        {...rest}
      />
      {renderCloseButtonOrSearchIcon()}
    </div>
  );
};

export { SearchBar, type SearchBarProps };
