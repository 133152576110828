import { faUser, faStethoscope } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Columns, Typography } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useSearchParams, useNavigate, useLocation } from "@shared/react-router-dom";
import { l } from "@unauthenticated/shared/locales/i18n";

import { RoleTile } from "./RoleTile";

import * as styles from "./SignUpTriage.styles";

const SignUpTriage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tablet } = useBreakpoints();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPractitioner, setIsPractitioner] = useState(true);
  const { search } = useLocation();

  const setRoute = () => {
    if (isPractitioner) {
      searchParams.set("step", "1");
      setSearchParams(searchParams);
    } else {
      navigate(`/patient-signup${search}`);
    }
  };

  return (
    <div css={styles.contentBox}>
      <Typography type="h2">{t(l.common.GetStartedWithFs)}</Typography>
      <Typography isMarginless>{t(l.common.WhichTypeOfAccount)}</Typography>
      <form onSubmit={setRoute}>
        <Columns css={styles.tilesContainter} preserveMobileColumns>
          <RoleTile
            name={t(l.common.PracAcccount)}
            icon={faStethoscope}
            isSelected={isPractitioner}
            onSelected={() => setIsPractitioner(true)}
            isFullHeight={tablet.lessThan}
          />
          <RoleTile
            name={t(l.common.PatientAccount)}
            icon={faUser}
            isSelected={!isPractitioner}
            onSelected={() => setIsPractitioner(false)}
            isFullHeight={tablet.lessThan}
          />
        </Columns>
        <Button
          type="submit"
          onClick={setRoute}
          isFullWidth={tablet.lessThan}
          data-e2e="role-selection-button"
        >
          {t(l.common.Next)}
        </Button>
      </form>
    </div>
  );
};

export { SignUpTriage };
