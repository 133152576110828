import { Spacer } from "@aviary";
import { useSearchParams } from "@shared/react-router-dom";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import {
  SignUpHeader,
  SignUpTitle,
  StoreLandingLayout,
  StoreLogo,
  StoreOffersPill,
} from "@unauthenticated/signupSignin/components";

import { EmailCheckForm } from "./EmailCheckForm";

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  ssoErrors?: string;
  storeId?: string;
  isPreview?: boolean;
  isStoreOwner: boolean;
}

const PatientAuthStartPage = ({
  patientAttributes,
  additionalAttributes,
  ssoErrors,
  storeId,
  isPreview = false,
  isStoreOwner,
}: Props) => {
  const { storeLogo, storeName, pracAvatar, isStoreIntake } = patientAttributes;
  const [searchParameters] = useSearchParams();
  const isIntake = searchParameters.get("intake") === "true";
  const showStoreOffersPill = !isStoreIntake || (isStoreIntake && !isIntake);

  return (
    <StoreLandingLayout
      storeName={storeName}
      pracAvatar={pracAvatar}
      isPreview={isPreview}
      isStoreOwner={isStoreOwner}
    >
      <SignUpHeader>
        <StoreLogo storeLogo={storeLogo} storeName={storeName} />
        <SignUpTitle title={storeName} />
        {showStoreOffersPill && <StoreOffersPill patientAttributes={patientAttributes} />}
      </SignUpHeader>
      <Spacer height="double" />
      <EmailCheckForm
        patientAttributes={patientAttributes}
        additionalAttributes={additionalAttributes}
        ssoErrors={ssoErrors}
        storeId={storeId}
        isPreview={isPreview}
      />
    </StoreLandingLayout>
  );
};

export { PatientAuthStartPage };
