import { useTranslation } from "react-i18next";

import { Typography } from "@aviary";
import { canUseWebp } from "@shared/utils/canUseWebp/canUseWebp";
import { l } from "@unauthenticated/shared/locales/i18n";

import * as styles from "./SignUpVideo.styles";

const SignUpPageRefresh = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography type="h5" css={styles.textStyling}>
        {t(l.practitionerSignUp.HealthcaresBestSupplement)}
      </Typography>

      {canUseWebp ? (
        <img src="./../sign-up-process.webp" css={styles.imgStyles} />
      ) : (
        <img src="./../sign-up-process.gif" css={styles.imgStyles} />
      )}
    </>
  );
};
export { SignUpPageRefresh };
